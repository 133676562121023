// import { useState, useEffect } from 'react';

import axios from 'axios';
import apiGetPollBySlug from '@/api/poll/[slug]';

// // https://julietonyekaoha.medium.com/react-cancel-all-axios-request-in-componentwillunmount-e5b2c978c071
// // checking on cancel calls on unmount?? chenck on the future
// export const useAxios = () => {
//   const [status, setStatus] = useState('idle');
//   const [data, setData] = useState(null);
//   const [source, setSource] = useState(null);
//   useEffect(() => {
//     setSource(axios.CancelToken.source);
//     return () => {
//       if (source) {
//         source.cancel('Component got unmounted');
//       }
//     };
//   }, []);
//   return { data, status };
// };

const headers = {
  'x-pollme-api-ver': 'v1'
};

export const fetchCurrentUser = async (params = {}, opts = {}) => {
  // console.log('on server poll fetcher');
  // console.log({ slug, accessKey });
  const POST_API = `/api/user`;
  const body = {
    params: {
      ...params
    }
  };
  const options = { ...opts, headers };
  // console.log({ body, options });
  const { data } = await axios.get(`${POST_API}`, body, options);
  return data;
};

export const updateUser = body => {
  const POST_API = '/api/user/update';
  const data = { ...body };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const deleteUser = (body = {}) => {
  const POST_API = '/api/user/delete';
  const data = { ...body };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const createNewPoll = (values, opts = {}) => {
  const POST_API = '/api/poll/new';
  const data = { ...values };
  const options = { ...opts, headers };
  // console.log({ data, options });
  return axios.post(`${POST_API}`, data, options);
};

export const updatePoll = ({ poll, accessKey }) => {
  const POST_API = '/api/poll/update';
  const data = { ...poll, accessKey };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const deletePoll = ({ id, accessKey }) => {
  const POST_API = '/api/poll/delete';
  const data = { id, accessKey };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const createNewResponse = (values, opts = {}) => {
  const POST_API = '/api/response/new';
  const data = { ...values };
  const options = { ...opts, headers };
  // console.log({ data, options });
  return axios.post(`${POST_API}`, data, options);
};

export const updateResponse = (values, opts = {}) => {
  const POST_API = '/api/response/update';
  const data = { ...values };
  const options = { ...opts, headers };
  // console.log({ data, options });
  return axios.post(`${POST_API}`, data, options);
};

export const deleteResponses = ({ pollID, accessKey, responsesIds }) => {
  const POST_API = '/api/response/delete';
  const data = { pollID, responsesIds, accessKey };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const fetcher = (...args) => fetch(...args).then(res => res.json());

export const axiosFetcher = async (url, opts = {}) => {
  const { accessKey } = opts;
  // console.log({ url, accessKey });
  // return axios.get(url).then(res => res.data)
  const body = {
    params: {
      accessKey
    }
  };
  const options = { headers };
  // console.log({ body, options });
  const { data } = await axios.get(`${url}`, body, options);
  return data;
};

export const fetchPollData = ({ slug, accessKey }, opts = {}) => {
  // console.log('on server poll fetcher');
  // console.log({ slug, accessKey });
  const POST_API = `/api/poll/${slug}`;
  const body = {
    params: {
      accessKey
    }
  };
  const options = { ...opts, headers };
  // console.log({ body, options });
  return axios.get(`${POST_API}`, body, options);
};

export const fetchServerPollBySlug = ({ slug }) => {
  // console.log('on server poll fetcher');
  // console.log({ slug, accessKey });
  const request = {
    query: { slug, checkIfUser: true }
  };
  return apiGetPollBySlug(request);
};

export const getUserStripeCustomer = async userID => {
  const POST_API = `/api/licenses/get-customer`;
  const body = {
    params: {
      userID
    }
  };
  const options = { headers };
  // console.log({ body, options });
  const { data } = await axios.get(`${POST_API}`, body, options);
  return data;
};

// eslint-disable-next-line camelcase
export const createStripeCustomer = async ({ userID, name, email, preferred_locales }) => {
  const POST_API = `/api/licenses/create-customer`;
  const body = {
    userID,
    name,
    email
    // preferred_locales: ['es', 'en'] // https://stripe.com/docs/api/customers/object#customer_object-preferred_locales
    // // https://support.stripe.com/questions/language-options-for-customer-emails
  };

  const options = { headers };
  // console.log({ body, options });
  const { data } = await axios.post(`${POST_API}`, body, options);
  return data;
};

export const updateUserSubscriptions = ({ subscriptionsIds }) => {
  const POST_API = '/api/licenses/update-subscriptions';
  const data = { subscriptionsIds };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};

export const updateNotionDBWithPoll = ({ poll, notionDB }) => {
  const POST_API = '/api/services/notion';
  const data = { poll, notionDB };
  const options = { headers };
  // console.log({ data, options });
  // return Promise.resolve();
  return axios.post(`${POST_API}`, data, options);
};
